import React from 'react';
import '../scss/schedule-blog-banner.scss';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';

export interface ScheduleBannerProps {
  dispatch: any;
}

const ScheduleBlogBanner: React.FC<ScheduleBannerProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));
  return (
    <section className="ScheduleBlog__container">
      <div className="ScheduleBlog__side">
        <div className="ScheduleBlog__description">
          <h3 className="ScheduleBlog__description-title">
            Comienza a construir tu futuro con nosotros
          </h3>
          <p className="ScheduleBlog__description-paragraph">
            ¡Da el primer paso y agenda una asesoría con los mejores consultores
            patrimoniales!
          </p>
          <Button
            fontSize="21px"
            paddingLeft="97px"
            paddingRight="97px"
            className="ScheduleBlog__button"
            onClickEvent={showModalQuote}
          >
            Agendar ahora
          </Button>
        </div>
      </div>
      <img
        src="/images/Proyectat-09.png"
        alt=""
        className="ScheduleBlog__image"
      />
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  ScheduleBlogBanner
);
