import React from 'react';
import Layout from '../components/layout';
import ScheduleBlogBanner from '../components/schedule-blog-banner';
import SEO from '../components/seo';
import '../scss/blog.scss';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import ReactPlayer from 'react-player';

export interface BlogProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        date: string;
        author: string;
        description: string;
        subtitle: string;
        image: string;
        intro: string;
        video_preview: string;
      };
      html: string;
    };
    header: {
      childImageSharp: {
        fluid: any;
      };
    };
    site: {
      siteMetadata: {
        socialMedia: {
          facebook: string;
          linkedin: string;
          instagram: string;
          youtube: string;
        };
      };
    };
    allMarkdownRemark: {
      nodes: any[];
    };
  };
}

const getDate = date => {
  const arrayDate = date.split('-');
  const day = arrayDate[0];
  const numMonth = Number(arrayDate[1]) - 1;
  const year = arrayDate[2];

  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  return <p> {`${day} ${months[numMonth]} ${year}`}</p>;
};

const Blog: React.FC<BlogProps> = ({ data }) => {
  const { site, markdownRemark, allMarkdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { instagram, facebook, linkedin, youtube } = site.siteMetadata.socialMedia;

  return (
    <Layout transparentOnTop={false}>
      <SEO title={frontmatter.title} description={frontmatter.subtitle} image={frontmatter.image} />
      <div>
        <section className="Blog-container container">
          <h1 className="Blog-header">
            Noticias del sector, consejos e información de nuestros expertos
          </h1>
          <hr />
          <h2 className="Blog-title">{frontmatter.title}</h2>

          <div className="Blog__image-container">
            <div className="Blog__image-separator">
              {!!frontmatter.video_preview &&
              frontmatter.video_preview.length > 0 ? (
                <ReactPlayer
                  controls
                  url={frontmatter.video_preview}
                  className="Blog-video"
                />
              ) : (
                <img
                  src={frontmatter.image}
                  alt="Imagen del blog"
                  className="Blog-image"
                />
              )}

              <div className="Blog__image-footer">
                <div className="Blog__image-social">
                  <a
                    href={facebook}
                    target="_blank"
                    className="Blog__image-social_link"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-3"
                        size="lg"
                        icon={faFacebookF}
                      />
                    </div>
                  </a>
                  <a
                    href={instagram}
                    target="_blank"
                    className="Blog__image-social_link"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-3"
                        size="lg"
                        icon={faInstagram}
                      />
                    </div>
                  </a>
                  <a
                    href={linkedin}
                    target="_blank"
                    className="Blog__image-social_link"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-3"
                        size="lg"
                        icon={faLinkedinIn}
                      />
                    </div>
                  </a>
                  <a
                    href={youtube}
                    target="_blank"
                    className="Blog__image-social_link"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-3"
                        size="lg"
                        icon={faYoutube}
                      />
                    </div>
                  </a>
                </div>
                <div className="Blog__image-date">
                  {getDate(frontmatter.date)}
                </div>
              </div>
            </div>
          </div>
          <h3 className="Blog-subtitle">{frontmatter.subtitle}</h3>
          <div className="Blog__content-container">
            <div className="Blog__content-description">
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
              <p className="Blog__content-author">Por: {frontmatter.author}</p>
            </div>
            <div className="Blog__content-last_articles">
              <h2 className="Last__articles-title">Últimos artículos</h2>
              <div className="Last__articles-container">
                <ul>
                  {allMarkdownRemark.nodes.map((blog, index) => (
                    <li key={`log-${index}`}>
                      <Link to={`/blog${blog.fields.slug}`}>
                        {blog.frontmatter.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <ScheduleBlogBanner />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD-MM-YYYY")
        title
        subtitle
        image
        intro
        video_preview
        author
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
    ) {
      nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
    site {
      siteMetadata {
        socialMedia {
          facebook
          linkedin
          instagram
          youtube
        }
      }
    }
  }
`;

export default Blog;
